export default [
  {
    header: 'Pembayaran',
  },
  {
    title: 'Riwayat Bayar',
    icon: 'MenuIcon',
    route: { name: 'pembayaran/riwayat-pembayaran' },
  },
]
