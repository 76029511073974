export default [
  {
    header: "Manajemen Event",
  },
  {
    title: "Event",
    icon: "UsersIcon",
    route: {name: "manajemen-event"},
  },
  {
    title: "Bundle",
    icon: "UsersIcon",
    route: {name: "manajemen-bundle"},
  },
];
