export default [
  {
    header: 'Manajemen Pengguna',
  },
  {
    title: 'Daftar Pengguna',
    icon: 'UsersIcon',
    route: { name: 'manajemen-users' },
  },
  {
    title: 'Access Control',
    icon: 'UnlockIcon',
    route: { name: 'access-control' },
  },
]
