export default [
  {
    header: "Manajemen Try Out",
  },
  {
    title: "Try Out",
    icon: "PenToolIcon",
    children: [
      {
        title: "TO",
        route: {
          name: "tryout",
          params: {jenis: ""},
        },
      },
      {
        title: "TO UTBK",
        // route: "tryout",
        route: {
          name: "tryout-utbk",
          params: {jenis: "utbk"},
        },
      },
      {
        title: "TO SKD",
        route: {
          name: "tryout-skd",
          params: {jenis: "skd"},
        },
      },
      {
        title: "TO CPNS",
        route: {
          name: "tryout-cpns",
          params: {jenis: "cpns"},
        },
      },
      {
        title: "TO BUMN",
        route: {
          name: "tryout-bumn",
          params: {jenis: "bumn"},
        },
      },
    ],
  },
];
