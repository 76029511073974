export default [
  {
    header: "Manajemen Materi",
  },
  {
    title: "Ebook",
    route: {
      name: "ebook",
    },
    icon: "BookIcon",
  },
  {
    title: "Ringkasan Materi",
    route: {
      name: "ringkasan-materi",
    },
    icon: "FileTextIcon",
  },
];
