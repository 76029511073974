export default [
  {
    header: 'Manajemen Kampus',
  },
  {
    title: 'Detail Kampus',
    icon: 'UsersIcon',
    route: { name: 'manajemen-kampus' },
  },
]
