export default [
  {
    header: "Voucher",
  },
  {
    title: "Voucher",
    icon: "PercentIcon",
    route: {name: "voucher"},
  },
];
