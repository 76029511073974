export default [
  {
    header: "Manajemen Video",
  },
  {
    title: "Video",
    route: {
      name: "video",
    },
    icon: "YoutubeIcon",
  },
  {
    title: "Live",
    route: {
      name: "live",
    },
    icon: "VideoIcon",
  },
];
