export default [
  {
    header: 'Modul',
  },
  {
    title: 'TPS',
    icon: 'UsersIcon',
    route: { name: 'modul/tps' },
  },
  {
    title: 'TKA SAINTEK',
    icon: 'UnlockIcon',
    // route: { name: 'access-control' },
  },
  {
    title: 'TKA SOSHUM',
    icon: 'UnlockIcon',
    // route: { name: 'access-control' },
  },
]
