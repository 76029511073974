export default [
  {
    header: "Manajemen Komunitas",
  },
  {
    title: "Komunitas",
    route: { name: 'komunitas' },
    icon: "UsersIcon",
  },
];
